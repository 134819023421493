<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-group">
            <label>Código (*):</label>
            <input type="number" class="form-control" v-model="item.tso_cdgo" required autofocus>
          </div>
          <div class="form-group">
            <label>Nombre (*):</label>
            <input type="text" class="form-control" v-model="item.tso_nmbre" required>
          </div>
          <div class="form-group">
            <label>Tipos de identificación (*):</label>
            <div>
              <a href="#" v-on:click.prevent="newTpoid()" data-toggle="modal" data-target="#tpoidModal"><plus-circle-icon class="custom-class"></plus-circle-icon>Agregar</a>
              <create-tpoid v-on:add-tpoid="addTpoid(tpoid)" v-bind:tpoid="tpoid"></create-tpoid>
            </div>
            <div>
              <table class="table table-bordered">
                <tr>
                  <th>Código</th>
                  <th>Nombre</th>
                  <th></th>
                </tr>
                <tr v-for="(i, index) in item.tpoids">
                  <td>{{ i.tid_cdgo }}</td>
                  <td>{{ i.tid_nmbre }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="deleteTpoid(index)" title="Eliminar Tipo de identificación"><delete-icon class="custom-class"></delete-icon></a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//import toastr from 'toastr';
  import { PlusCircleIcon, EditIcon, DeleteIcon } from 'vue-feather-icons';
  import CreateTpoid from './CreateTpoid.vue';

export default {
  components: {
    PlusCircleIcon, EditIcon, DeleteIcon,
    CreateTpoid
  },
  data() {
    return {
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: "",
      item: {},
      message: "",
      buttontext: "",
      tpoid: {},
      tpoids: [],
      addTid: true,
    };
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    user() {
      return this.$store.state.user;
    },
    cancelButtonVisibility() {
      if (this.addInd) {
        return "visibility:hidden";
      } else {
        return "visibility:visible";
      }
    },
  },
  created: function() {
    this.add = this.$route.params.add;
    //this.fetchTpoid();
    if (this.add) {
      this.title = "Crear un Tipo de solicitante";
      this.buttontext = "Agregar";
      
      this.item.tpoids = [];
    } else {
      this.title = "Editar un Tipo de solicitante";
      this.buttontext = "Actualizar";
      this.getItem(this.$route.params.id);
    }
  },
  methods: {
    setClose(close) {
      this.close = close;
    },
    saveItem() {
      if (this.add) {
        this.item.cmp_id = String(this.$store.state.company);
        let uri = "/tposolics/add";
        //alert(this.item);
        this.axios
          .post(uri, this.item)
          .then((response) => {
            console.log(response);
            this.$router.replace({ name: "DisplayTposolic" });
            this.message = "Registro guardado";
          })
          .catch((err) => {
            console.log(err);
            this.message = "¡Error al crear el registro! " + err;
          });
      } else {
        let uri = "/tposolics/update/" + this.item._id;
        this.axios.post(uri, this.item).then((response) => {
          if (this.close) {
            this.$router.replace({ name: "DisplayTposolic" });
          }
          this.message = "Registro guardado";
        });
      }
    },
    getItem(id) {
      let uri = '/tpoids/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpoids = response.data;

        let uri = "/tposolics/edit/" + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;

          for (let i = 0; i < this.item.tpoids.length; i++){
            for (let j = 0; j < this.tpoids.length; j++){
              if (String(this.item.tpoids[i].tid_id) == String(this.tpoids[j]._id)){
                this.item.tpoids[i].tid_cdgo = this.tpoids[j].tid_cdgo;
                this.item.tpoids[i].tid_nmbre = this.tpoids[j].tid_nmbre;
                j = this.tpoids.length;
              }
            }
          }
        });        
      });
    },
    cancel() {
      this.$router.replace({ name: "DisplayTposolic" });
    },
    fetchTpoid()
    {
      let uri = '/tpoids/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpoids = response.data;
      });
    },
    newTpoid() {
      this.tpoid = {};
      this.addTid = true;
    },
    addTpoid(i) {
      //alert(indice);
      if (i.tid_id == null){
        alert('Seleccione el tipo de identificación');
        return;
      }

      for (var j = 0; j < this.item.tpoids.length; j++){
        if (String(i.tid_id) == String(this.item.tpoids[j].tid_id)){
          alert('Este valor ya se ha agregado');
          return;
        }
      }

      for (var j = 0; j < this.tpoids.length; j++){
        if (String(i.tid_id) == String(this.tpoids[j]._id)){
          i.tid_cdgo = this.tpoids[j].tid_cdgo;
          i.tid_nmbre = this.tpoids[j].tid_nmbre;
          j = this.tpoids.length;
        }
      }

      if (this.addTid) {
        this.item.tpoids.push({ tid_id: i.tid_id, tid_cdgo: i.tid_cdgo, tid_nmbre: i.tid_nmbre });
      } else {
        /*
        this.item.tpoids[this.editInd].ind_id = i.ind_id;
        //this.item.indices[this.editInd].ind_cdgo = i.ind_cdgo;
        this.item.tpoids[this.editInd].ind_nmbre = i.ind_nmbre;
        this.item.tpoids[this.editInd].ixd_valor = i.ixd_valor;
        this.indicesButtonText = 'Agregar';
        */
      }
      this.tpoid = {};
      this.addTid = true;
      this.showModal = false;
    },
    deleteTpoid(i) {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        this.item.tpoids.splice(i,1);
      }
    },    
  },
};
</script>

  <style>
.warning {
  color: #ff0000;
}
</style>
